<template>
	<div>
        <Dialog style="background: #727376;" :header="$t('Register')" v-model:visible="$store.state.display.register" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true">
            <div class="p-fluid">
                <strong>{{$t('MessLog1')}}</strong>
                <div class="p-text-center"><strong v-if="!valid">{{$t('MessLog2')}}</strong></div>
                    <div class="p-field p-grid">
                    <div class="p-col-12 p-md-12" v-if="!valid">
						<label>{{$t('Email')}}</label>
						<InputText v-model="email" type="email" autofocus  @keyup.enter="Validacion"/>
					</div>
                    <div class="p-col-12 p-md-12" v-if="valid">
						<label>{{$t('Email')}}</label>
						<InputText v-model="email" type="email" :disabled="valid == true" autofocus/>
					</div>
                    <div class="p-col-12 p-md-6" v-if="valid">
                        <label class="p-col-12 p-md-2">{{$t('Validation Code')}}</label>
                        <InputText v-model="code" type="text" :disabled="valcode==code"/>
                    </div>
                    <div class="p-col-12 p-md-6" v-if="valid">
                        {{$t('Validation Code Mens')}}
                    </div>
					<div class="p-col-12 p-md-6" v-if="valid && valcode==code">
						<label>{{$t('Name')}}</label>
						<InputText v-model="name" type="text" id="name" />
					</div>
					<div class="p-col-12 p-md-6" v-if="valid && valcode==code">
						<label>{{$t('Last')}}</label>
						<InputText v-model="last" type="text" id="last" />
					</div>
					<div class="p-col-12 p-md-6" v-if="valid && valcode==code">
						<label>{{$t('Password')}}</label>
                        <InputText v-model="passw" type="password" id="passw2" />
					</div>
					<div class="p-col-12 p-md-6" v-if="valid && valcode==code">
						<label>{{$t('Confirm Password')}}</label>
                        <InputText v-model="passwconf" type="password" id="passw3" />
					</div>
                    <div class="p-col-12 p-md-12" v-if="valid && valcode==code">
						{{$t('Password Format')}}
					</div>
                </div>	
			</div>	
            <template #footer>
                <Button :label="$t('Accept')" icon="pi pi-check" class="p-button-secondary" @click="Register" autofocus  v-if="valid && valcode==code"/>
                <Button :label="$t('Validate Mail')" icon="pi pi-check" class="p-button-secondary" @click="Validacion" autofocus  v-else/>
                <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="close"/>
            </template>
        </Dialog>
	</div>
</template>

<script>
import API from "../service/API";
import Crypto from "crypto-js";
import { useI18nPlugin } from '@unify/vuex-i18n';

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		}
	},
    data() {
        return {
            displayRegister: false,
            email: null,
            passw: null,
			name: null,
			last: null,
            passwconf: null,
            size: '40vw',
            valid: false,
            code: null,
            valcode: null,
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
        this.i18n = useI18nPlugin();
    },
    methods: {
        clean() {
            this.email = null;
            this.name = null;
            this.last = null;
            this.passw = null;
            this.passwconf = null;
            this.valid = false;
            this.valcode = null;
            this.code = null;
       },
        close() {
            this.displayRegister = false;
            this.clean();
            this.$store.state.display.register = false;
            //this.$emit('close-register', event);
        },
        Validacion() {
            if (this.email == null) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Error in Mail'), life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.email)) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Error in Mail'), life: 10000});
            } else {
                this.$store.commit('Loading');
                const Consult = new API('Security','SendCode');
                Consult.Process(null,{
                    email: this.email,
                    leng: this.i18n.locale()
                }).then(response => {
                    //this.$store.state.error = response;
                    this.$toast.add(Consult.Mens(response.code,this.i18n.locale(),this.$t(response.message)));
                    if(response.code){
                        this.valcode = response.code;
                        this.valid = true;
                    } 
                    this.$store.commit('Loading');                  
                });         
            } 
        },
        Register() {
            if (this.name == null) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Error in Name'), life: 10000});
            } else if (this.passw == null) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Password Error'), life: 10000});
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_.!@#$%^&*])(?=.{8,})/.test(this.passw)) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Password Error'), life: 10000});
            } else if (this.passw != this.passwconf) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Confirmation Failed'), life: 10000});
            } else if (this.code != this.valcode) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Error in Code'), life: 10000});
            } else {
                this.$store.commit('Loading');
                const cifrado = Crypto.HmacSHA1(this.passw, "btcoin").toString();
                const Consult = new API('Security','Register');
                Consult.Process(null,{
                    name: this.name,
                    last: this.last,
                    email: this.email,
                    passw: cifrado,
                    leng: this.i18n.locale(),
                }).then(response => {
                    //this.$store.state.error = response;
                    this.$toast.add(Consult.Mens(response.authenticated,this.i18n.locale(),this.$t(response.message)));
                    if(response.authenticated){
                        this.$store.commit('Login',response);
                        if(response.menu){
                           this.$store.commit('Menu',response.menu);
                        }
                        this.$store.state.display.register = false;
                        //this.displayRegister = false;
                        //this.onMenuToggle();
                        this.$store.state.display.login = true;
                    }
                    this.$store.commit('Loading');
                });
                this.clean();
            } 
        },
        onMenuToggle(event) {
            this.$emit('aprob-login', event);
            this.$emit('close-login', event);
        },	

	},
    watch: {
		display(newValue) {
			this.displayRegister = newValue;
		}
	},


}
</script>